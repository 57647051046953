<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import appStore from "~/store";
import router from "~/router/router";

const {
  project,
  projectLoading,
  projectDirty,
} = storeToRefs(appStore.projectStore);

const currentNavigation = ref(undefined);

const orderedDashboards = computed(() => {
  return [
    {
      ref: "projectManage",
      name: "Manage",
      singleWidget: {
        type: "projectManage",
      },
    },
    {
      ref: "projectActivity",
      name: "Activity",
      singleWidget: {
        type: "projectActivity",
        properties: {
          style: {
            height: "calc(100vh - 190px)",
          },
        },
      },
    },
    {
      ref: "projectDataFlow",
      name: "Data Flow",
      singleWidget: {
        type: "projectDataFlow",
        properties: {
          style: {
            height: "calc(100vh - 190px)",
          },
        },
      },
    },
    {
      ref: "projectDataForms",
      name: "Data Forms",
      singleWidget: {
        type: "projectDataForms",
        properties: {
          style: {
            height: "calc(100vh - 190px)",
          },
        },
      },
    },
    {
      ref: "projectGuidance",
      name: "Guidance Sets",
      singleWidget: {
        type: "projectGuidance",
        properties: {
          style: {
            height: "calc(100vh - 190px)",
          },
        },
      },
    },
    {
      ref: "projectOptions",
      name: "Available Options",
      singleWidget: {
        type: "projectOptions",
      },
    },
    {
      ref: "projectTemplateCreate",
      name: "Create Template",
      singleWidget: {
        type: "projectTemplateCreate",
      },
    },
  ] as any[];
});

watch(orderedDashboards, () => {
  if (orderedDashboards.value && orderedDashboards.value.length > 0 && currentNavigation.value === undefined) {
    currentNavigation.value = orderedDashboards.value[0];
  }
}, {
  immediate: true,
  deep: true,
});

const saving = ref(false);

async function saveProjectManagement() {
  try {
    saving.value = true;
    appStore.projectStore.saveProjectManagement();
  } finally {
    saving.value = false;
  }
}

async function cancelAdmin() {
  await appStore.projectStore.reloadProject();
  // Send the user back to the project home
  await router.push({ path: `/a/o/${project.value.organization.id}/p/${project.value.id}/home` });
}
</script>

<template>
  <div v-if="project" class="bg-white">
    <header>
      <div>
        <header>
          <div class="float-right mt-4 pr-6">
            <KodexaButton
              v-if="currentNavigation?.ref !== 'projectEmailNotification' && currentNavigation?.ref !== 'projectDocumentAssignment'"
              class="mr-1"
              :loading="saving"
              :disabled="!projectDirty"
              icon="content-save" name="saveChanges" size="small" type="primary"
              @click="saveProjectManagement"
            >
              Save Changes
            </KodexaButton>
            <KodexaButton
              v-if="currentNavigation?.ref !== 'projectEmailNotification' && currentNavigation?.ref !== 'projectDocumentAssignment'"
              class="mr-1"
              :disabled="saving"
              icon="cancel" name="saveChanges" size="small" type="secondary"
              @click="cancelAdmin"
            >
              Cancel
            </KodexaButton>
          </div>
          <div v-if="!projectLoading" class="border-b border-gray-200 dark:border-gray-700">
            <ul class="-mb-px ml-2 flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
              <li
                v-for="(item) in orderedDashboards" :key="item.ref"
                class="mr-2"
                @click="currentNavigation = item"
              >
                <a
                  :id="item.ref"
                  :class="item.ref === currentNavigation?.ref ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
                  class="text-md"
                >
                  <MaterialDesignIcon
                    v-if="item?.icon" :name="item.icon" class="text-theme-primary mr-3"
                    size="24"
                  />
                  {{ item?.name }}
                </a>
              </li>
            </ul>
          </div>
        </header>
      </div>
    </header>
    <div v-if="!projectLoading">
      <KodexaDashboard
        v-if="currentNavigation"
        :model-value="currentNavigation"
        :setup-mode="false"
        entry-point="project"
      />
    </div>
  </div>
</template>
