<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->
<script setup lang="ts">
import type { PanzoomObject } from "@panzoom/panzoom";
import Panzoom from "@panzoom/panzoom";
import { Button, ButtonGroup, SplitButton, Toolbar } from "@progress/kendo-vue-buttons";
import { useElementSize, watchDebounced } from "@vueuse/core";
import { storeToRefs } from "pinia";
import type { ILogObj } from "tslog";
import { Logger } from "tslog";
import type { Ref } from "vue";
import type { DropTargetMonitor } from "vue3-dnd";
import { useDrop } from "vue3-dnd";
import {
  alignLeftIcon,
  arrowRotateCwIcon,
  bordersAllIcon,
  caretAltLeftIcon,
  chevronDownIcon,
  chevronLeftIcon,
  chevronRightIcon,
  codeIcon,
  connectorIcon,
  editToolsIcon,
  plusIcon,
  rotateIcon,
  tableWizardIcon,
  textClipIcon,
  textboxIcon,
  wrenchIcon,
  zoomInIcon,
  zoomOutIcon,
} from "@progress/kendo-svg-icons";
import { downArrowIcon } from "@progress/kendo-vue-layout";
import { notify } from "notiwind";
import type { SpatialContext, SpatialNode, SpatialOptions } from "~/components/document/spatial";
import { SpatialRenderEngine } from "~/components/document/spatial";
import appStore from "~/store";
import type { FindLineHit } from "~/store/useDocumentView";
import { createDocumentViewerStore } from "~/store/useDocumentView";
import type { KeyboardShortcut } from "~/store/useKeyboard";
import type { ProcessingStepViewer, TagInstance } from "~/store/useWorkspace";
import { usePlatform } from "~/store/usePlatform";
import { scrollIntoView } from "~/utils/scroll";
import type { Assistant, CustomEvent, DataForm } from "~/model";
import { getColorByBgColor } from "~/utils/colors";
import type { TagMetadata } from "~/store/useProject";
import type { SelectedTag } from "~/components/document/document";

const props = defineProps<{ viewId: string; kioskMode: boolean }>();

const {
  allDataForms,
  assistants,
  tagMetadataMap,
  refresher,
} = storeToRefs(appStore.projectStore);
const {
  focusedNodeUuid,
  focusTagUuid,
  disabledTaxonomies,
} = storeToRefs(appStore.workspaceStore);

const useDocumentViewStore: any = createDocumentViewerStore(props.viewId);

const platformStore = usePlatform();

const {
  imageHeight,
  imageWidth,
  selectionContext,
  documentFamily,
  numPages,
  isSidecar,
  page,
  contentExceptions,
  nativeDocument,
  loadingMessage,
  documentLoading,
  documentViewer,
  pageTags,
  notParsed,
} = storeToRefs(useDocumentViewStore);

const el = ref(null);
const currentHoverElement: Ref<Element | null> = ref(null);
const currentHoverNodeUuid: Ref<string | null> = ref(null);

const resolveDrag = function (item: unknown, monitor: DropTargetMonitor) {
  if (monitor && monitor.getClientOffset()) {
    const hoverEl = document.elementFromPoint(monitor.getClientOffset().x, monitor.getClientOffset().y);
    const hoverElUuid = hoverEl?.attributes.getNamedItem("nodeuuid")?.value;
    if (hoverEl && hoverElUuid) {
      if (currentHoverNodeUuid.value !== hoverElUuid) {
        if (currentHoverNodeUuid.value && currentHoverElement.value) {
          currentHoverElement.value.classList.remove("kodexa-selected-node");
        }
        currentHoverNodeUuid.value = hoverElUuid || null;
        currentHoverElement.value = hoverEl;
        currentHoverElement.value.classList.add("kodexa-selected-node");
      }
    } else {
      if (currentHoverNodeUuid.value && currentHoverElement.value) {
        currentHoverElement.value.classList.remove("kodexa-selected-node");
        currentHoverNodeUuid.value = null;
      }
    }
  } else {
    currentHoverElement.value = null;
    currentHoverNodeUuid.value = null;
  }
};

const kodexaSpatialContainer: Ref<HTMLElement | null> = ref(null);
const kodexaSpatialPageWrapper: Ref<HTMLElement | null> = ref(null);

const kodexaTextContainer = ref(null);
const {
  width,
  height,
} = useElementSize(el);
const loading: Ref<boolean> = ref(true);
const showAdvanced: Ref<boolean> = ref(false);

const log: Logger<ILogObj> = new Logger();
const isSpatial = ref(true);

const spatialOptions: Ref<SpatialOptions> = ref({
  highlightOnlyTagUuids: [],
  labeling: true,
  showImages: false,
  showLines: false,
  showText: false,
  showTooltips: true,
  showContentAreas: false,
  showWords: false,
  focusTagUuids: [],
  invertImages: false,
  showPageImages: false,
  highlightOnlyFocus: false,
  focusTagUuid: undefined,
  width: 0,
  height: 0,
  focusNodeUuid: undefined,
} as SpatialOptions);

// These are all the watches
watch(documentLoading, async (newDocumentLoading) => {
  log.info(`New document loading: ${newDocumentLoading}`);
  if (!newDocumentLoading) {
    await render();
  }
});

watch(page, async (newPage) => {
  if (newPage !== null && newPage !== undefined) {
    await render();
  }
});

const handleFocusNode = function (newFocusNodeUuid: string) {
  log.info(`New focus node uuid: ${newFocusNodeUuid}`);
  if (newFocusNodeUuid) {
    const node = kddbDocument.value.getNodeById(newFocusNodeUuid);
    if (node) {
      spatialOptions.value.focusNodeUuid = node.uuid;
      const page = node.getPage();
      log.info(`Setting page to ${page?.index}`);
      if (page) {
        useDocumentViewStore.setPage(page.index);
      }
      spatialOptions.value.focusTagUuid = undefined;
      spatialOptions.value.highlightOnlyFocus = false;
      setTimeout(() => {
        log.info(`Scrolling to node - ${newFocusNodeUuid}`);
        const nodeElement = document.getElementById(`node-${newFocusNodeUuid}`);
        if (nodeElement && kodexaSpatialPageWrapper.value) {
          log.info("Found node and scolling");
          scrollIntoView(nodeElement, kodexaSpatialPageWrapper.value);
        }
      }, 1000);
    }
  } else {
    spatialOptions.value.focusNodeUuid = undefined;
  }
};

watch(focusedNodeUuid, (newFocusNodeUuid) => {
  useDocumentViewStore.setFocusNodeUuid(newFocusNodeUuid);
  handleFocusNode(newFocusNodeUuid);
  nextTick(() => {
    render();
  });
});

watch(disabledTaxonomies, async () => {
  await render();
}, { deep: true });

const handleFocusTag = function (newFocusTagUuid: string) {
  log.info(`New focus tag uuid: ${newFocusTagUuid}`);
  if (newFocusTagUuid) {
    spatialOptions.value.focusTagUuid = newFocusTagUuid;
    spatialOptions.value.highlightOnlyFocus = true;
  } else {
    spatialOptions.value.focusTagUuid = undefined;
    spatialOptions.value.highlightOnlyFocus = false;
  }
};

watch(focusTagUuid, (newFocusTagUuid) => {
  handleFocusTag(newFocusTagUuid);
  render();
});

watch([width, height], () => {
  if (width.value && height.value) {
    if (width.value !== imageWidth.value || height.value !== imageHeight.value) {
      useDocumentViewStore.updateImageSize(width.value, height.value);
      // TODO we need to debounce the render
      render();
    }
  }
}, {
  deep: true,
  immediate: true,
});

watch(spatialOptions, async () => {
  await render();
}, { deep: true });

const [collect, kodexaLabelDrop] = useDrop(() => ({
  accept: ["label"],
  drop(item: unknown) {
    if (!currentHoverNodeUuid.value) {
      return false;
    } else {
      const currentNode = kddbDocument.value.findNodeByUUID(currentHoverNodeUuid.value);

      if (currentNode) {
        // @ts-expect-error - item is a TagInstance
        const tagInstance = item.tagInstance as TagInstance;
        useDocumentViewStore.addTag(tagMetadataMap.value.get(tagInstance.taxon.path), [currentNode]);
        render();
      }
    }
  },
  hover: (item: unknown, monitor: DropTargetMonitor) => {
    resolveDrag(item, monitor);
  },
  collect: (monitor: DropTargetMonitor) => ({
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
    draggingColor: monitor.getItemType() as string,
    clientOffset: monitor.getClientOffset(),
  }),
}));

const spatialContext = ref<SpatialContext | undefined>();
const currentTagReference = ref<Element | undefined>();
const focusedNode = ref<SpatialNode | undefined>();
let panZoom: PanzoomObject | undefined;

const { kddbDocument } = storeToRefs(useDocumentViewStore);

watch(notParsed, async () => {
  if (!notParsed.value) {
    await render();
  }
});

async function render() {
  try {
    log.info(`Rendering page ${page.value}`);

    if (notParsed.value) {
      log.info("Document is not parsed");
      isSpatial.value = false;
      loading.value = false;
      return;
    }

    if (documentFamily.value?.mixins?.includes("spatial")) {
      if (!kddbDocument.value) {
        log.info("KDDB is not loaded");
        return;
      }

      let pageUuid = useDocumentViewStore.getPageUuid(page.value);

      if (focusTagUuid.value) {
        const nodes = kddbDocument.value.findNodesByTagUuid(focusTagUuid.value);
        if (nodes && nodes.length > 0) {
          pageUuid = nodes[0].getPage()?.uuid;
          useDocumentViewStore.setPage(nodes[0].getPage()?.index);
        }
      }

      if (pageUuid === undefined) {
        log.warn(`Unable to find page uuid for page ${page.value}, opening first page`);
        if (kddbDocument.value.contentNode?.getChildren()) {
          pageUuid = kddbDocument.value.contentNode.getChildren()[0]?.uuid;
        } else {
          log.error("Unable to find any pages");
          return;
        }
      }

      loading.value = true;
      try {
        log.info("Starting render");
        if (kodexaTextContainer.value) {
          const spatialRenderEngine = new SpatialRenderEngine();
          spatialRenderEngine.attachToCanvas(kodexaTextContainer.value);
          spatialRenderEngine.setDocument(kddbDocument.value);
          spatialOptions.value.width = imageWidth.value;
          spatialOptions.value.height = imageHeight.value;

          spatialContext.value = await spatialRenderEngine.render(pageUuid, spatialOptions.value, appStore);
          log.info("Completed render");

          loading.value = false;
          useDocumentViewStore.updateImageSize(spatialContext.value.width, spatialContext.value.height);

          nextTick(() => {
            currentTagReference.value = spatialContext.value?.currentTagReference;
            focusedNode.value = spatialContext.value?.focusedNode;
            useDocumentViewStore.buildSelection();
          }).then(() => {
            log.info("Updated current tag reference");
          });
        }
      } catch (e) {
        log.error("Rendering failed");
        log.error(e);
        loading.value = false;
      }
    } else {
      log.info("Document is not spatial");
      isSpatial.value = false;
      loading.value = false;
    }
  } catch (e) {
    log.error(e);
    loading.value = false;
  }
}

onMounted(async () => {
  if (kodexaTextContainer.value && kodexaSpatialContainer.value && el.value) {
    if (focusTagUuid.value) {
      spatialOptions.value.focusTagUuid = focusTagUuid.value;
      spatialOptions.value.highlightOnlyFocus = true;
    }

    // These are basically called as though they are in setup
    await Promise.all([useDocumentViewStore.loadNative(), useDocumentViewStore.loadDocument()]);
  } else {
    log.error("Unable to find spatial containers");
  }
});

const availableDataForms = computed(() => {
  return allDataForms.value.filter(dataForm => dataForm.entrypoints.includes("documentFamily"))
    .map((dataForm: DataForm) => {
      return {
        icon: "form",
        text: dataForm.name,
        ref: dataForm.ref,
      };
    });
});

const openDataForm = function (event: any) {
  const dataForm = allDataForms.value.find((dataForm: DataForm) => dataForm.ref === event.item.ref);
  appStore.workspaceStore.addDataForm(dataForm, documentFamily.value);
};

function pageDown() {
  appStore.workspaceStore.setFocusTagUuid(undefined);
  useDocumentViewStore.setFocusNodeUuid(undefined);
  useDocumentViewStore.setPage(page.value - 1);
}

function pageUp() {
  appStore.workspaceStore.setFocusTagUuid(undefined);
  useDocumentViewStore.setFocusNodeUuid(undefined);
  useDocumentViewStore.setPage(page.value + 1);
}

const currentPage = computed(() => {
  return page.value + 1;
});

function setCurrentPage(value) {
  appStore.workspaceStore.setFocusTagUuid(undefined);
  if (value === null || value < 1) {
    value = 1;
  }
  if (value <= numPages.value) {
    useDocumentViewStore.setPage(value - 1);
  } else {
    useDocumentViewStore.setPage(numPages.value - 1);
  }
}

watch(() => selectionContext?.value?.refresh, () => {
  render();
});

function zoomIn() {
  if (panZoom) {
    panZoom.zoomIn();
  }
}

function zoomOut() {
  if (panZoom) {
    panZoom.zoomOut();
  }
}

function openExceptions() {
  platformStore.setCurrentSidebar("exceptions");
}

const showAssistantEvent = ref(false);

const assistantEventTypes = computed(() => {
  const testableAssistants = assistants.value.filter((assistant: Assistant) => {
    return assistant.definition && assistant.showInTraining && assistant.definition.eventTypes?.find((eventType: CustomEvent) => {
      return eventType.contentObject;
    });
  });
  return testableAssistants.map((assistant: Assistant) => {
    const eventType = assistant.definition && assistant.definition.eventTypes?.find((eventType: CustomEvent) => {
      return eventType.contentObject && assistant.showInTraining;
    });
    return {
      name: assistant.name,
      description: assistant.description,
      id: assistant.id,
      assistant,
      eventType,
    };
  });
});

const selectedEventType: Ref<undefined | CustomEvent> = ref(undefined);

function closeAssistantEvent() {
  showAssistantEvent.value = false;
}

async function sendToAssistant(selectEventType: CustomEvent) {
  const { projectDirty } = storeToRefs(appStore.projectStore);
  const { isDirty } = storeToRefs(appStore.workspaceStore);

  if (projectDirty.value || isDirty.value) {
    if (await appStore.projectStore.saveAllChanges(true)) {
      selectedEventType.value = selectEventType;
      showAssistantEvent.value = true;
    } else {
      selectedEventType.value = selectEventType;
      showAssistantEvent.value = true;
    }
  } else {
    selectedEventType.value = selectEventType;
    showAssistantEvent.value = true;
  }
}

const findText = ref("");
const lastLineIndex = ref(0);
const findLoading = ref(false);
const findHits: Ref<FindLineHit[]> = ref([]);

function findNext() {
  if (focusTagUuid.value) {
    appStore.workspaceStore.setFocusTagUuid(undefined);
  }

  if (focusedNode.value) {
    useDocumentViewStore.setFocusNodeUuid(undefined);
  }

  if (findHits.value.length === 0) {
    return;
  }

  if (lastLineIndex.value === findHits.value.length - 1) {
    lastLineIndex.value = 0;
  } else {
    lastLineIndex.value = lastLineIndex.value + 1;
  }
  appStore.workspaceStore.setFocusedNodeUuid(findHits.value[lastLineIndex.value].uuid);
}

function findPrevious() {
  if (focusTagUuid.value) {
    appStore.workspaceStore.setFocusTagUuid(undefined);
  }

  if (focusedNode.value) {
    useDocumentViewStore.setFocusNodeUuid(undefined);
  }

  if (findHits.value.length === 0) {
    return;
  }

  if (lastLineIndex.value === 0) {
    lastLineIndex.value = findHits.value.length - 1;
  } else {
    lastLineIndex.value = lastLineIndex.value - 1;
  }

  appStore.workspaceStore.setFocusedNodeUuid(findHits.value[lastLineIndex.value].uuid);
}

watchDebounced(findText, () => {
  if (findText.value.length > 0) {
    lastLineIndex.value = 0;
    appStore.workspaceStore.setFocusedNodeUuid(undefined);
    findHits.value = useDocumentViewStore.findLines(findText.value);
    if (findHits.value.length > 0) {
      appStore.workspaceStore.setFocusedNodeUuid(findHits.value[0].uuid);
    } else {
      appStore.workspaceStore.setFocusedNodeUuid(undefined);
    }
  } else {
    appStore.workspaceStore.setFocusedNodeUuid(undefined);
    findHits.value = [];
  }
}, {
  debounce: 300,
});

function gotoHit(hit: FindLineHit) {
  appStore.workspaceStore.setFocusedNodeUuid(hit.uuid);
  lastLineIndex.value = findHits.value.findIndex((findHit: FindLineHit) => findHit.uuid === hit.uuid);
}

const ORIGINAL_SCALE = 1;

const bottomThird = {
  key: "alt+2",
  altKey: "alt+™",
  description: "Display bottom third of image",
  callback: () => {
    if (kodexaSpatialContainer.value && kodexaSpatialPageWrapper.value) {
      // Reset zoom to original scale
      if (panZoom) {
        panZoom.zoom(ORIGINAL_SCALE, { animate: true });
      }
      // Wait for zoom animation to complete before scrolling
      setTimeout(() => {
        const dy = kodexaSpatialContainer.value.offsetHeight;
        kodexaSpatialPageWrapper.value.scroll({ top: dy, behavior: "smooth" });
      }, 300); // Adjust this delay if needed
    }
  },
} as KeyboardShortcut;

const middleThird = {
  key: "alt+5",
  altKey: "alt+∞",
  description: "Display middle third of image",
  callback: () => {
    if (kodexaSpatialContainer.value !== null && kodexaSpatialPageWrapper.value !== null) {
      // Reset zoom to original scale
      if (panZoom) {
        panZoom.zoom(ORIGINAL_SCALE, { animate: true });
      }
      // Wait for zoom animation to complete before scrolling
      setTimeout(() => {
        const dy = (kodexaSpatialContainer.value.offsetHeight / 8) * 2;
        kodexaSpatialPageWrapper.value.scroll({ top: dy, behavior: "smooth" });
      }, 300); // Adjust this delay if needed
    }
  },
} as KeyboardShortcut;

const topThird = {
  key: "alt+6",
  altKey: "alt+§",
  description: "Display top third of image",
  callback: () => {
    if (kodexaSpatialContainer.value !== null && kodexaSpatialPageWrapper.value !== null) {
      // Reset zoom to original scale
      if (panZoom) {
        panZoom.zoom(ORIGINAL_SCALE, { animate: true });
      }
      // Wait for zoom animation to complete before scrolling
      setTimeout(() => {
        kodexaSpatialPageWrapper.value.scroll({ top: 0, behavior: "smooth" });
      }, 300); // Adjust this delay if needed
    }
  },
} as KeyboardShortcut;

const gotoFirstPage = {
  key: "alt+shift+arrowleft",
  altKey: "alt+shift+arrowleft",
  description: "Go to first page of document",
  callback: () => {
    useDocumentViewStore.setPage(0);
  },
} as KeyboardShortcut;

const gotoLastPage = {
  key: "alt+shift+arrowright",
  altKey: "alt+shift+arrowright",
  description: "Go to last page of document",
  callback: () => {
    useDocumentViewStore.setPage(useDocumentViewStore.numPages - 1);
  },
} as KeyboardShortcut;

const gotoPreviousPage = {
  key: "alt+pageup",
  altKey: "alt+“",
  description: "Go to previous page",
  callback: () => {
    if (page.value > 0) {
      useDocumentViewStore.setPage(page.value - 1);
    }
  },
} as KeyboardShortcut;

const gotoNextPage = {
  key: "alt+pagedown",
  altKey: "alt+‘",
  description: "Go to next page",
  callback: () => {
    if (page.value < useDocumentViewStore.numPages - 1) {
      useDocumentViewStore.setPage(page.value + 1);
    }
  },
} as KeyboardShortcut;

const gotoZoomIn = {
  key: "alt+/",
  altKey: ["alt+/", "alt+÷"],
  description: "Zoom in",
  callback: () => {
    zoomIn();
  },
} as KeyboardShortcut;

const gotoZoomOut = {
  key: "alt+*",
  altKey: ["alt+*", "alt+•"],
  description: "Zoom out",
  callback: () => {
    zoomOut();
  },
} as KeyboardShortcut;

const fitWidth = {
  key: "alt+insert",
  altKey: "alt+insert",
  description: "Fit Width",
  callback: () => {
    if (kodexaSpatialContainer.value) {
      const zoomFactor = kodexaSpatialContainer.value.offsetWidth / (kodexaSpatialPageWrapper.value.offsetWidth);
      panZoom?.zoom(zoomFactor, { animate: true });
    }
  },
} as KeyboardShortcut;

const fitHeight = {
  key: "alt+delete",
  altKey: "alt+backspace",
  description: "Fit Height",
  callback: () => {
    if (kodexaSpatialContainer.value) {
      const zoomFactor = kodexaSpatialPageWrapper.value.offsetHeight / kodexaSpatialContainer.value.offsetHeight;
      panZoom?.zoom(zoomFactor, { animate: true });
      // recenter the pdf after zooming out to full height
      setTimeout(() => {
        if (kodexaSpatialContainer.value && kodexaSpatialPageWrapper.value) {
          scrollIntoView(kodexaSpatialContainer.value.element, kodexaSpatialContainer.value.element);
        }
      }, 50);
    }
  },
} as KeyboardShortcut;

onBeforeUnmount(() => {
  if (panZoom) {
    panZoom.destroy();
  }

  const useKeyboardStore = useKeyboard();
  useKeyboardStore.removeShortcut(bottomThird);
  useKeyboardStore.removeShortcut(middleThird);
  useKeyboardStore.removeShortcut(topThird);
  useKeyboardStore.removeShortcut(gotoFirstPage);
  useKeyboardStore.removeShortcut(gotoLastPage);
  useKeyboardStore.removeShortcut(gotoPreviousPage);
  useKeyboardStore.removeShortcut(gotoNextPage);
  useKeyboardStore.removeShortcut(gotoZoomIn);
  useKeyboardStore.removeShortcut(gotoZoomOut);
  useKeyboardStore.removeShortcut(fitWidth);
  useKeyboardStore.removeShortcut(fitHeight);
});

onMounted(async () => {
  // set-up all the shortcut keys
  const useKeyboardStore = useKeyboard();
  useKeyboardStore.addShortcut(bottomThird);
  useKeyboardStore.addShortcut(middleThird);
  useKeyboardStore.addShortcut(topThird);
  useKeyboardStore.addShortcut(gotoFirstPage);
  useKeyboardStore.addShortcut(gotoLastPage);
  useKeyboardStore.addShortcut(gotoPreviousPage);
  useKeyboardStore.addShortcut(gotoNextPage);
  useKeyboardStore.addShortcut(gotoZoomIn);
  useKeyboardStore.addShortcut(gotoZoomOut);
  useKeyboardStore.addShortcut(fitWidth);
  useKeyboardStore.addShortcut(fitHeight);

  if (kodexaTextContainer.value && kodexaSpatialContainer.value && el.value) {
    panZoom = Panzoom(kodexaSpatialContainer.value, {
      maxScale: 5,
      step: 0.1,
      disablePan: true,
      excludeClass: "",
      cursor: "default",
      pinchAndPan: true,
    });

    if (focusTagUuid.value) {
      spatialOptions.value.focusTagUuid = focusTagUuid.value;
      spatialOptions.value.highlightOnlyFocus = true;
    }

    await Promise.all([useDocumentViewStore.loadNative(), useDocumentViewStore.loadDocument()]);
  } else {
    log.error("Unable to find spatial containers");
  }
});

const executions = computed(() => {
  if (documentViewer.value && documentViewer.value.executionId) {
    const executionStore = createExecutionStore(documentViewer.value.executionId);
    return executionStore.orderedExecutions;
  }
  return [];
});

const openTextView = function () {
  // We want to get all the lines in the document and then show them in a text view
  const lines = kddbDocument.value.getLines();
  if (lines) {
    appStore.workspaceStore.createTextViewer(`${documentFamily.value?.path} (Raw).txt`, lines.map(line => `${line.content}('${line.uuid}')`).join("\n"));
  } else {
    // Show a confirm dialog stating we have no lines
    appStore.platformStore.showConfirmDialog("No Lines", "There are no lines in this document");
  }
};

const pageMetadata = computed(() => {
  const pageNode = useDocumentViewStore.getPageNode(page.value);
  if (pageNode) {
    return pageNode.getFeature("content", "summary").value[0];
  } else {
    return "No summary";
  }
});

const openExplainGraph = function () {
  const processStepView = {
    viewType: "processingStep",
    id: `processing-step-view-${documentFamily.value.id}`,
    documentFamilyId: documentFamily.value.id,
    title: `${documentFamily.value.path} (Explain)`,
  } as ProcessingStepViewer;
  appStore.workspaceStore.addView(processStepView);
};

const showExecutionView = ref(false);

const availableExports = [
  { icon: "code", text: "xml (friendly)" },
  { icon: "code", text: "xml" },
  { icon: "code", text: "json" },
  { icon: "code", text: "Data Objects" },
];

const openExport = async function (event: any) {
  // we want to get the format for the document family and then show it
  // however we want to do it based on the document objects we have
  let output = "";
  let format = "";
  if (event.item.text === "xml") {
    output = await appStore.workspaceStore.getExportForDocument(documentFamily.value, "xml", documentViewer.value.executionId);
    format = "xml";
  } else if (event.item.text === "xml (friendly)") {
    output = await appStore.workspaceStore.getExportForDocument(documentFamily.value, "xml(friendly)", documentViewer.value.executionId);
    format = "xml";
  } else if (event.item.text === "json") {
    output = await appStore.workspaceStore.getExportForDocument(documentFamily.value, "json", documentViewer.value.executionId);
    format = "json";
  } else if (event.item.text === "Data Objects") {
    const dataObjects = Array.from(appStore.workspaceStore.dataObjects.values()).filter(dataObject => dataObject.documentFamily.id === documentFamily.value?.id);
    output = JSON.stringify(dataObjects, undefined, 2);
    format = "json";
  }
  if (output !== "") {
    appStore.workspaceStore.createTextViewer(`${documentFamily.value?.path} (${event.item.text})`, output, undefined, format);
  } else {
    notify({
      title: "No Data",
      message: "There is no data to export",
      group: "error",
    });
  }
};

const executionSteps = computed(() => {
  if (documentViewer.value && documentViewer.value.executionId) {
    const executionStore = createExecutionStore(documentViewer.value.executionId);
    const views = executionStore.orderedExecutions.map((execution) => {
      return {
        icon: "code",
        text: `${execution.description} (${execution.status})`,
        id: execution.id,
      };
    });

    executionStore.orderedExecutions.forEach((execution) => {
      if (execution.context) {
        // Create a view for each key in context with the text as the value
        Object.keys(execution.context).forEach((key) => {
          let format;
          if (key.endsWith("xml")) {
            format = "xml";
          } else if (key.endsWith("json")) {
            format = "json";
          } else if (key.endsWith("yaml")) {
            format = "yaml";
          } else if (key.endsWith("py")) {
            format = "python";
          }

          views.push({
            icon: "code",
            text: `${key}`,
            textContent: execution.context[key],
            format,
            id: `${execution.id}-${key}`,
          });
        });
      }
    });

    return views;
  }
  return [];
});

function openExecutionStep(event: any) {
  if (event.item.textContent) {
    appStore.workspaceStore.createTextViewer(`${event.item.text} (raw).txt`, event.item.textContent, undefined, event.item.format);
  } else {
    appStore.workspaceStore.createTextViewer(`${event.item.text} (logs).txt`, "", event.item.id);
  }
}

watch(refresher, async () => {
  await render();
});

const panZoomEnabled = ref(false);

function togglePanzoom() {
  if (panZoom) {
    log.info("Toggling panzoom");
    const startScale = panZoom.getScale();
    const startX = panZoom.getPan().x;
    const startY = panZoom.getPan().y;
    panZoom.destroy();
    if (kodexaSpatialContainer.value) {
      panZoom = Panzoom(kodexaSpatialContainer.value, {
        maxScale: 5,
        step: 0.1,
        excludeClass: panZoomEnabled.value ? "kodexa-selected-node" : "",
        animate: true,
        cursor: panZoomEnabled.value ? "grab" : "text",
        disablePan: !panZoomEnabled.value,
        pinchAndPan: panZoomEnabled.value,
        startY,
        startX,
        startScale,
      });
    }
  } else {
    log.error("Unable to find panzoom");
  }
}

// Function to handle the keydown event
function handleKeyDown(event) {
  if (event.key === "Shift" && !panZoomEnabled.value) {
    log.info("shift key is pressed.");
    panZoomEnabled.value = true;
    togglePanzoom();
  }
}

// Function to handle the keyup event
function handleKeyUp(event) {
  if (event.key === "Shift" && panZoomEnabled.value) {
    panZoomEnabled.value = false;
    togglePanzoom();
  }
}

// Function to handle mouse wheel event
function handleWheel(event) {
  if (event.shiftKey) {
    event.preventDefault();
    const delta = event.deltaY || event.detail || event.wheelDelta;
    if (delta > 0) {
      zoomOut();
    } else {
      zoomIn();
    }
  }
}

onMounted(() => {
  // Attach the event listeners to the whole document
  document.addEventListener("keydown", handleKeyDown);
  document.addEventListener("keyup", handleKeyUp);
  document.addEventListener("wheel", handleWheel, { passive: false });
});

onUnmounted(() => {
  // Remove the event listeners from the whole document
  document.removeEventListener("keydown", handleKeyDown);
  document.removeEventListener("keyup", handleKeyUp);
  document.removeEventListener("wheel", handleWheel);
});

function moveFromSidecar() {
  appStore.workspaceStore.openDocumentView(documentFamily.value);
}

const pdfPage = computed(() => {
  return useDocumentViewStore.getPdfPageNumberFromIndex(page.value);
});

function showTagPopup(event) {
  log.info("Right click to show tag popup");
  if (selectionContext.value.selectedNodes.length > 0) {
    useDocumentViewStore.showTagPopup(event.x, event.y, props.viewId);
  }
}

const currentRotation = ref(undefined);
const overlayRotation = ref(0);

const availableRotation = computed(() => {
  const options = [
    { icon: "rotate-left", text: "Rotate 0", angle: 0, overlay: 0 },
    { icon: "rotate-left", text: "Rotate Left", angle: -90, overlay: -90 },
    { icon: "rotate-right", text: "Rotate Right", angle: 90, overlay: 90 },
    { icon: "rotate-right", text: "Rotate 180", angle: 180, overlay: 180 },
  ];
  if (spatialContext.value?.angle) {
    if (currentRotation.value === undefined) {
      currentRotation.value = -spatialContext.value.angle;
    }
    options.push({
      icon: "rotate-left",
      text: `Rotate ${spatialContext.value.angle} (based on analysis)`,
      angle: spatialOptions.value.angle,
      overlay: 0,
    });
    return options;
  } else {
    if (currentRotation.value === undefined) {
      currentRotation.value = 0;
    }
    return options;
  }
});

function rotateDocument(event) {
  currentRotation.value = event.item.angle;
  overlayRotation.value = event.item.overlay;
}

const { contentTaxonomies } = storeToRefs(appStore.projectStore);

const rootTaxons = computed(() => {
  const roots = [] as TagMetadata[];
  const uniqueTaxonIds = new Set<string>();
  const existingPageTagIds = new Set(pageTags.value.map(tag => tag.taxon.id));
  contentTaxonomies.value.forEach((taxonomy) => {
    taxonomy.taxons.forEach((taxon) => {
      const tagMetadata = tagMetadataMap.value.get(taxon.path);
      if (tagMetadata && !uniqueTaxonIds.has(tagMetadata.taxon.id) && !existingPageTagIds.has(tagMetadata.taxon.id)) {
        roots.push(tagMetadata);
        uniqueTaxonIds.add(tagMetadata.taxon.id);
      }
    });
  });
  return roots;
});

function addPageTag(taxon: TagMetadata) {
  if (taxon) {
    // We need to get the node for the page
    const pageNode = useDocumentViewStore.getPageNode(page.value);
    useDocumentViewStore.addTag(taxon, [pageNode]);
  }
}

function deletePageTag(tag: TagInstance) {
  const selectedTag = { name: tag.taxon.name, path: tag.taxon.path, uuid: tag.tagUuid } as SelectedTag;
  useDocumentViewStore.removeTag(selectedTag);
}
</script>

<template>
  <div>
    <div>
      <Toolbar v-if="isSpatial" class="-mt-1 flex border-0 bg-white">
        <Button :svg-icon="arrowRotateCwIcon" title="Refresh" :togglable="false" :disabled="loading" @click="render()" />
        <Button
          :svg-icon="textClipIcon" title="Show Text" :togglable="true" :selected="spatialOptions.showText"
          :disabled="loading" @click="spatialOptions.showText = !spatialOptions.showText"
        />
        <Button :svg-icon="zoomInIcon" title="Zoom In" :disabled="loading" @click="zoomIn" />
        <Button :svg-icon="zoomOutIcon" title="Zoom Out" :disabled="loading" @click="zoomOut" />
        <Button :svg-icon="chevronLeftIcon" title="Previous Page" :disabled="page === 0 || loading" @click="pageDown" />

        <div class="shrink">
          <VDropdown>
            <KodexaNumericInput v-model="currentPage" v-debounce:300ms="setCurrentPage" name="currentPage" class="w-12" />
            <template #popper>
              <div class="bg-white shadow" style="max-height: 400px; width: 350px; overflow: auto">
                <div class="mx-4 my-2 text-xs text-gray-600 p-2">
                  {{ pageMetadata }}
                </div>
              </div>
            </template>
          </VDropdown>
          <div class="ml-2 mt-2 shrink">
            of {{ numPages }}
          </div>
        </div>
        <Button
          :svg-icon="chevronRightIcon" title="Next Page" :disabled="(numPages <= (page + 1)) || loading"
          @click="pageUp"
        />

        <Button v-if="isSidecar" :svg-icon="caretAltLeftIcon" title="Move to tab" @click="moveFromSidecar" />
        <SplitButton
          v-if="availableRotation.length > 0" :svg-icon="rotateIcon" :items="availableRotation"
          :disabled="loading" @itemclick="rotateDocument"
        />
        <SplitButton
          v-if="availableDataForms.length > 0 && !isSidecar" :svg-icon="tableWizardIcon" :items="availableDataForms"
          :disabled="loading" @itemclick="openDataForm"
        />
        <SplitButton
          v-if="documentViewer.executionId" icon="clock-arrow-rotate"
          :items="executionSteps"
          :svg-icon="textboxIcon"
          :disabled="loading"
          @itemclick="openExecutionStep"
        />
        <VDropdown v-if="!documentViewer.executionId && assistantEventTypes.length > 0 && !isSidecar">
          <Button
            :svg-icon="downArrowIcon"
            name="sendToAssistants"
            size="small"
            :disabled="!assistants || loading"
            :togglable="false"
          >
            <span class="ml-1 text-xs font-light text-gray-600">Test</span>
          </Button>
          <template #popper>
            <div class="bg-white shadow" style="max-height: 400px; width: 350px; overflow: auto">
              <div class="mx-4 my-2">
                <div
                  v-for="assistantEvent in assistantEventTypes"
                  :key="assistantEvent.name" v-close-popper
                  class="mt-1 flex cursor-pointer space-x-2 p-2 hover:bg-gray-100"
                  @click="sendToAssistant(assistantEvent)"
                >
                  <div class="shrink-0">
                    <img
                      class="mx-auto size-10 rounded-md" :src="assistantEvent.assistant.definition.imageUrl"
                      alt=""
                    >
                  </div>
                  <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                    <p class="text-sm text-gray-900">
                      {{ assistantEvent.assistant.name }}
                    </p>
                    <p class="text-xs font-light text-gray-600">
                      {{ assistantEvent.assistant.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </VDropdown>
        <div class="grow">
          <KodexaTextInput
            v-model="findText" name="findText" placeholder="Find text..." :loading="findLoading"
            :show-clear="true" @keydown.enter="findNext"
          />
        </div>
        <Button
          :svg-icon="chevronLeftIcon" title="Find previous" :disabled="findText === '' || findHits.length === 0"
          @click="findPrevious"
        />
        <Button
          :svg-icon="chevronRightIcon" title="Find next" :disabled="findText === '' || findHits.length === 0"
          @click="findNext"
        />
        <VMenu>
          <Button
            :svg-icon="chevronDownIcon" title="Show matches"
            :disabled="findText === '' || findHits.length === 0"
          />
          <template #popper>
            <div class="bg-white shadow sm:rounded-lg" style="max-height: 400px; width: 350px; overflow: auto">
              <div class="mx-4 my-2">
                <div
                  v-for="hit in findHits" :key="hit.uuid" class="mt-1 cursor-pointer p-2 hover:bg-gray-100"
                  @click="gotoHit(hit)"
                >
                  <p class="text-sm">
                    {{ hit.content }}
                  </p>
                  <p class="mt-1 text-xs font-light">
                    Page {{ hit.pageNumber }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </VMenu>
        <span v-if="findHits.length > 0" class="text-xs"><span v-if="findHits.length > 0">{{
          lastLineIndex + 1
        }} of </span>{{ findHits.length }} hits</span>
        <span
          v-if="contentExceptions && contentExceptions.length > 0 && !isSidecar" class="ml-2"
          @click="openExceptions"
        >
          <MaterialDesignIcon name="alert-circle-outline" class="mr-1 text-red-500" size="20" />
          <span class="mt-1">{{ contentExceptions.length }} content exceptions</span>
        </span>
        <Button
          :svg-icon="wrenchIcon" title="Show Advanced" :togglable="true" :selected="showAdvanced"
          :disabled="loading" @click="showAdvanced = !showAdvanced"
        />

        <Button
          v-if="showAdvanced" v-tooltip="`View as Text`"
          :svg-icon="editToolsIcon" title="View as Text" :togglable="false"
          :disabled="loading" @click="openTextView"
        />
        <Button
          v-if="showAdvanced" v-tooltip="`View Explain Plan`"
          :svg-icon="connectorIcon" title="Explain Plan" :togglable="false"
          :disabled="loading" @click="openExplainGraph"
        />
        <SplitButton
          :svg-icon="codeIcon" :items="availableExports"
          :disabled="loading" @itemclick="openExport"
        />
        <ButtonGroup v-if="showAdvanced">
          <Button
            v-tooltip="`Show Lines`"
            :svg-icon="alignLeftIcon" title="Show Lines" :togglable="true" :selected="spatialOptions.showLines"
            :disabled="loading" @click="spatialOptions.showLines = !spatialOptions.showLines"
          />
          <Button
            v-tooltip="`Show Words`"
            :svg-icon="textClipIcon" title="Show Words" :togglable="true" :selected="spatialOptions.showWords"
            :disabled="loading" @click="spatialOptions.showWords = !spatialOptions.showWords"
          />
          <Button
            v-tooltip="`Show Content Areas`"
            :svg-icon="bordersAllIcon" title="Show Content Areas" :togglable="true"
            :selected="spatialOptions.showContentAreas"
            :disabled="loading" @click="spatialOptions.showContentAreas = !spatialOptions.showContentAreas"
          />
        </ButtonGroup>
        <div v-if="!documentViewer.executionId" class="relative">
          <div class="flex -space-x-2 overflow-hidden">
            <VMenu v-for="(pageTag) in pageTags" :key="pageTag.uuid" :popper-triggers="['hover']">
              <div
                class="relative inline-flex h-8 w-8 rounded-full ring-2 ring-white items-center justify-center"
                :style="{ 'background-color': pageTag.taxon.color }"
              >
                <span class="text-center text-xs" :style="{ color: getColorByBgColor(pageTag.taxon.color) }">{{ pageTag.taxon?.label?.charAt(0) }}</span>
                <span class="sr-only">{{ pageTag.taxon.label }}</span>
              </div>
              <template #popper>
                <div class="relative inline-block h-22 rounded-full ring-2 ring-white w-96">
                  <div class="flex items-center justify-between">
                    <div class="p-4">
                      <div>
                        <span id="pageTagName" class="wrap-text">{{ pageTag.taxon.label }}</span>
                      </div>
                      <div class="text-xs text-gray-400">
                        {{ pageTag.metadata.taxonomy.name }}
                      </div>
                    </div>
                    <div class="p-4">
                      <KodexaButton name="deletePageTag" type="danger" size="small" icon="delete" @click="deletePageTag(pageTag)" />
                    </div>
                  </div>
                </div>
              </template>
            </VMenu>
            <VDropdown v-if="rootTaxons.length > 0">
              <Button
                class="relative inline-flex h-8 w-8 rounded-full ring-2 ring-white items-center justify-center bg-gray-200"
                :svg-icon="plusIcon"
                title="Add Page Tag"
              />
              <template #popper>
                <div class="bg-white shadow sm:rounded-lg" style="max-height: 400px; width: 200px; overflow: auto">
                  <div class="mx-4 my-2">
                    <div
                      v-for="rootTaxon in rootTaxons"
                      :key="rootTaxon.taxon.id"
                      class="mt-1 cursor-pointer p-2 hover:bg-gray-100"
                      @click="addPageTag(rootTaxon)"
                    >
                      <div>{{ rootTaxon.taxon.label }}</div>
                      <div class="text-xs text-gray-400">
                        {{ rootTaxon.taxonomy.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </VDropdown>
          </div>
        </div>
        <span v-if="documentFamily.activeAssistant">
          👀{{ documentFamily.activeAssistant.name }} is working on this document
        </span>
      </Toolbar>
    </div>
    <div ref="el">
      <div
        v-if="isSpatial" ref="kodexaSpatialPageWrapper"
        style="height: calc(100vh - 16rem); overflow-y: scroll; width: 100%; position: relative"
      >
        <div
          v-if="loading" class="flex content-center justify-center"
          style="position: absolute; z-index: 10000; width: 100%; height: 600px"
        >
          <KodexaLoader :message="loadingMessage" />
        </div>
        <div
          class="kodexa-spatial-page-wrapper"
          :style="{
            width: '100%',
            position: 'absolute',
          }"
        >
          <div
            ref="kodexaSpatialContainer" class="kodexa-spatial-page"
            :style="{ width: '100%', height: `${imageHeight + 50}px`, cursor: panZoomEnabled ? 'grab' : 'text' }"
            @contextmenu.prevent="showTagPopup"
          >
            <div
              v-if="nativeDocument && !documentLoading"
              :style="{ 'opacity': 1.0,
                        'position': 'absolute',
                        'transform': `rotate(${(currentRotation || 0)}deg)`,
                        'height': `${imageHeight + 50}px`,
                        'width': '100%',
                        'transform-origin': 'center' }"
            >
              <VuePdf :src="nativeDocument" :page="pdfPage" />
            </div>
            <div
              v-if="nativeDocument && documentLoading"
              :style="{ opacity: 1.0,
                        position: 'absolute',
                        height: `${imageHeight}px`,
                        width: `${imageWidth}px` }"
            >
              <!-- Tailwind Skeleton -->
              <div class="animate-pulse bg-gray-100 h-full w-full" />
            </div>
            <div
              :ref="kodexaLabelDrop"
              :style="{ 'position': 'absolute',
                        'opacity': loading ? 0 : 1,
                        'width': `100%`,
                        'height': `${imageHeight}px`,
                        'z-index': 50 }"
            >
              <div ref="kodexaTextContainer" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!isSpatial || notParsed"
      style="height: calc(100vh - 12rem); overflow-y: scroll; width: 100%; position: relative"
    >
      <div class="mt-20 text-center">
        <MaterialDesignIcon name="alertBox" class="mx-auto size-12 text-red-600" aria-hidden="true" />
        <h3 class="mt-4 text-sm font-semibold text-gray-900">
          No Spatial Data
        </h3>
        <p class="mt-2 text-sm text-gray-500">
          This document has no spatial data, please configure the project to provide a model or assistant to
          provide spatial data.
        </p>
      </div>
    </div>
    <KodexaSendAssistantEventPopup
      :key="documentFamily.id" v-model="showAssistantEvent"
      :selected-event-type="selectedEventType" @close="closeAssistantEvent"
    />
    <KodexaExecutionPopup v-model="showExecutionView" :executions="executions" />
  </div>
</template>

<style>
.kodexa-spatial-page-wrapper {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.kodexa-selected-node {
  border: 1px solid red;

  position: relative; /* WebKit Bug 173872 */
  transition: 0.3s ease;
  /*min-width: 1.0em;*/
  /*align-items: center;*/
}

.kodexa-tag-spatial-container {
  cursor: text;
  z-index: 5000;
}

.kodexa-tag-pulse {
  outline: yellow;
  outline-offset: .3rem;
  padding-top: 4px;
  padding-bottom: 4px;
  transform: scale(1.6);
  animation: pulse 1.2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
