<script setup lang="ts">
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import type { PropType } from "vue";
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { filterIcon } from "@progress/kendo-svg-icons";
import type { DashboardWidget } from "~/model";
import { createGridHelper } from "~/store/useGridHelper";
import { useListTasks } from "~/api/activity/activity";
import KodexaTaskCard from "~/components/kodexa-task-card.vue";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
  },
});

const widget = ref(props.widget);

const gridHelper = createGridHelper("user-tasks-list", {
  page: 1,
  pageSize: 6,
  filter: "",
  query: "",
  sort: "createdOn:desc",
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListTasks(gridQuery.value);

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

const columns = computed(() => {
  const baseColumns = [
    { title: "Title", field: "title", cell: "title", width: "500px" },
    { title: "Status", field: "status.status", cell: "status" },
    { title: "Owner", field: "owner.firstName", cell: "owner" },
    { title: "Created", field: "createdOn", cell: "created" },
  ];

  return baseColumns;
});

watch(gridHelper.pageSettings, (newPageSettings) => {
  if (newPageSettings) {
    gridQuery.value.pageSize = newPageSettings.take as number;
  }
});

const computedQuery = computed({
  get() {
    return gridHelper.query;
  },
  set(value) {
    gridHelper.setQuery(value);
  },
});

const el = ref(null);
const { width, height } = useElementSize(el);

const gridStyle = computed(() => {
  const rowSpan = widget.value?.properties?.defaultPosition.rowSpan || 6;
  const height = (rowSpan * 85) - 130;
  return {
    rowSpan: `${rowSpan}`,
    height: `${height}px`,
  };
});
</script>

<template>
  <div ref="el" class="mx-4">
    <Toolbar class="mb-1 border-0 bg-white">
      <label for="query" class="sr-only">Query</label>
      <KodexaTextInput
        id="filterProjects"
        v-model="computedQuery" :show-clear="true" type="text" name="filterTasks"
        class="-ml-1 block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm" placeholder="Filter tasks"
      />
      <KodexaDropDown
        name="taskStatus"
        v-model="gridQuery.status"
        class="ml-2"
        :items="[
          { text: 'All', value: '' },
          { text: 'Open', value: 'status:open' },
          { text: 'In Progress', value: 'status:in_progress' },
          { text: 'Completed', value: 'status:completed' },
        ]"
        :show-clear="true"
        placeholder="Status"
      />
      <Button
        class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        :svg-icon="filterIcon" title="Filter" :togglable="true" @click="gridHelper.toggleFilter()"
      />
      <KodexaArticle class="ml-2 mt-2" article-id="9893941" text="Learn more about tasks" :slide="true" />
      <ToolbarSpacer />
    </Toolbar>
    <Grid
      class="h-full"
      :style="gridStyle"
      :data-items="data ? data.content : []"
      :pageable="pageSettings"
      :loader="isLoading"
      :sort="sort"
      scrollable="scrollable"
      :skip="pageSettings.skip as number"
      :total="pageSettings.total as number"
      :page-size="pageSettings.take as number"
      :take="pageSettings.take as number"
      :columns="columns"
      :filterable="showFilter"
      :filter="filter"
      :resizable="true"
      :sortable="true"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
    >
      <GridNoRecords>
        <KodexaGridNoRecords
          :error="error" :is-loading="isLoading" :is-error="isError"
          no-records-message="No tasks found"
        />
      </GridNoRecords>
      <template #title="{ props }">
        <td>
          <KodexaTaskCard :task="props.dataItem" />
        </td>
      </template>
      <template #status="{ props }">
        <td />
      </template>
      <template #owner="{ props }">
        <td>
          <KodexaProjectOwner :project="props.dataItem" @updated="refetch" />
        </td>
      </template>
      <template #created="{ props }">
        <td>
          <KodexaDateTimeView :date="props.dataItem.createdOn" :ago="true" />
        </td>
      </template>
    </Grid>
  </div>
</template>

<style scoped>
.open-org-link {
  color: #007bff; /* Change to your preferred link color */
  text-decoration: underline;
  cursor: pointer;
}

.open-org-link:hover {
  color: #0056b3; /* Darker shade for hover effect */
}
</style>
