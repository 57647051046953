/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  ListRetainedGuidancesParams
} from '../../model/listRetainedGuidancesParams'
import type {
  PageRetainedGuidance
} from '../../model/pageRetainedGuidance'
import type {
  RetainedGuidance
} from '../../model/retainedGuidance'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get a resource with the provided ID
 */
export const getRetainedGuidance = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<RetainedGuidance>(
      {url: `/api/retainedGuidance/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRetainedGuidanceQueryKey = (id: MaybeRef<string>,) => {
    return ['api','retainedGuidance',id] as const;
    }

    
export const getGetRetainedGuidanceQueryOptions = <TData = Awaited<ReturnType<typeof getRetainedGuidance>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRetainedGuidance>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetRetainedGuidanceQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRetainedGuidance>>> = ({ signal }) => getRetainedGuidance(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRetainedGuidance>>, TError, TData> 
}

export type GetRetainedGuidanceQueryResult = NonNullable<Awaited<ReturnType<typeof getRetainedGuidance>>>
export type GetRetainedGuidanceQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetRetainedGuidance = <TData = Awaited<ReturnType<typeof getRetainedGuidance>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRetainedGuidance>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRetainedGuidanceQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the given instance
 */
export const updateRetainedGuidance = (
    id: MaybeRef<string>,
    retainedGuidance: MaybeRef<RetainedGuidance>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
retainedGuidance = unref(retainedGuidance);
      
      return customAxios<RetainedGuidance>(
      {url: `/api/retainedGuidance/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: retainedGuidance
    },
      options);
    }
  


export const getUpdateRetainedGuidanceMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRetainedGuidance>>, TError,{id: string;data: RetainedGuidance}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateRetainedGuidance>>, TError,{id: string;data: RetainedGuidance}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRetainedGuidance>>, {id: string;data: RetainedGuidance}> = (props) => {
          const {id,data} = props ?? {};

          return  updateRetainedGuidance(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateRetainedGuidanceMutationResult = NonNullable<Awaited<ReturnType<typeof updateRetainedGuidance>>>
    export type UpdateRetainedGuidanceMutationBody = RetainedGuidance
    export type UpdateRetainedGuidanceMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateRetainedGuidance = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRetainedGuidance>>, TError,{id: string;data: RetainedGuidance}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateRetainedGuidanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteRetainedGuidance = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/retainedGuidance/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteRetainedGuidanceMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRetainedGuidance>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteRetainedGuidance>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRetainedGuidance>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteRetainedGuidance(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteRetainedGuidanceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRetainedGuidance>>>
    
    export type DeleteRetainedGuidanceMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteRetainedGuidance = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRetainedGuidance>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteRetainedGuidanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listRetainedGuidances = (
    params?: MaybeRef<ListRetainedGuidancesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageRetainedGuidance>(
      {url: `/api/retainedGuidance`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListRetainedGuidancesQueryKey = (params?: MaybeRef<ListRetainedGuidancesParams>,) => {
    return ['api','retainedGuidance', ...(params ? [params]: [])] as const;
    }

    
export const getListRetainedGuidancesQueryOptions = <TData = Awaited<ReturnType<typeof listRetainedGuidances>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListRetainedGuidancesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listRetainedGuidances>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListRetainedGuidancesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listRetainedGuidances>>> = ({ signal }) => listRetainedGuidances(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listRetainedGuidances>>, TError, TData> 
}

export type ListRetainedGuidancesQueryResult = NonNullable<Awaited<ReturnType<typeof listRetainedGuidances>>>
export type ListRetainedGuidancesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListRetainedGuidances = <TData = Awaited<ReturnType<typeof listRetainedGuidances>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListRetainedGuidancesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listRetainedGuidances>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListRetainedGuidancesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createRetainedGuidance = (
    retainedGuidance: MaybeRef<RetainedGuidance>,
 options?: SecondParameter<typeof customAxios>,) => {
      retainedGuidance = unref(retainedGuidance);
      
      return customAxios<RetainedGuidance>(
      {url: `/api/retainedGuidance`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: retainedGuidance
    },
      options);
    }
  


export const getCreateRetainedGuidanceMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRetainedGuidance>>, TError,{data: RetainedGuidance}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createRetainedGuidance>>, TError,{data: RetainedGuidance}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createRetainedGuidance>>, {data: RetainedGuidance}> = (props) => {
          const {data} = props ?? {};

          return  createRetainedGuidance(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateRetainedGuidanceMutationResult = NonNullable<Awaited<ReturnType<typeof createRetainedGuidance>>>
    export type CreateRetainedGuidanceMutationBody = RetainedGuidance
    export type CreateRetainedGuidanceMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateRetainedGuidance = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRetainedGuidance>>, TError,{data: RetainedGuidance}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateRetainedGuidanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForRetainedGuidance = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/retainedGuidance/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForRetainedGuidanceQueryKey = (id: MaybeRef<string>,) => {
    return ['api','retainedGuidance',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForRetainedGuidanceQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForRetainedGuidance>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForRetainedGuidance>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForRetainedGuidanceQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForRetainedGuidance>>> = ({ signal }) => getChangeSequenceForRetainedGuidance(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForRetainedGuidance>>, TError, TData> 
}

export type GetChangeSequenceForRetainedGuidanceQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForRetainedGuidance>>>
export type GetChangeSequenceForRetainedGuidanceQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetChangeSequenceForRetainedGuidance = <TData = Awaited<ReturnType<typeof getChangeSequenceForRetainedGuidance>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForRetainedGuidance>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChangeSequenceForRetainedGuidanceQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



