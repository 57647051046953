<script lang="ts" setup>
import type { Store } from "pinia";
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import { computed } from "vue";
import appStore from "~/store";
import KodexaTextInput from "~/components/inputs/kodexa-text-input.vue";
import KodexaClipboardable from "~/components/util/kodexa-clipboardable.vue";
import type { DataFlowNode } from "~/model";
import KodexaCheckbox from "~/components/inputs/kodexa-checkbox.vue";

const props = defineProps({
  node: {
    type: Object as PropType<DataFlowNode>,
  },
});

const emit = defineEmits(["selected", "step-deleted"]);

const { dataStores, projectDirty } = storeToRefs(appStore.projectStore);

const dataStore = computed(() => {
  return dataStores.value.find((store: Store) => props.node && store.ref === props.node.id?.split("//")[1]);
});

watch(
  () => dataStore.value,
  (newValue) => {
    appStore.projectStore.addToDataStoresToUpdate(newValue);
  },
  { deep: true },
);

const tabs = [
  { ref: "general", name: "General" },
  { ref: "email", name: "Email" },
];

const currentNavigation = ref(tabs[0]);
</script>

<template>
  <div>
    <div v-if="dataStore" class="flex h-full flex-col bg-white">
      <div class="-mt-8">
        <div class="border-b border-gray-200 dark:border-gray-700">
          <ul class="-mb-px ml-2 flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
            <li
              v-for="(item) in tabs" :key="item.ref"
              class="mr-2"
              @click="currentNavigation = item"
            >
              <a
                class="text-md"
                :class="item.ref === currentNavigation?.ref ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
              >
                <MaterialDesignIcon
                  v-if="item?.icon" :name="item.icon" size="18"
                  class="text-theme-primary mr-3"
                />
                {{ item?.name }}
              </a>
            </li>
          </ul>
        </div>
        <div v-if="currentNavigation?.ref === 'general'" class="mx-2 mt-2">
          <div class="col-span-6 mb-1 sm:col-span-3">
            <KodexaTextInput
              v-model="dataStore.name"
              name="name"
              type="text"
              autocomplete="dataStore-name"
              label="Name"
            />
          </div>
          <div class="mt-4 mb-4 text-gray-600">
            <KodexaClipboardable
              :content="dataStore.ref"
              :show-content="false"
              message="Copy Store Reference"
              name="ref"
              color="black"
              label="Ref"
            />
          </div>
          <div class="col-span-6 mb-1 sm:col-span-3">
            <KodexaTextArea name="dataStore-description" :model-value="dataStore.description" label="Description" :rows="9" />
          </div>
          <div class="col-span-6 mb-2 sm:col-span-3">
            <KodexaCheckbox
              v-model="dataStore.deleteProtection"
              name="deleteProtection"
              label="Delete Protection"
              hint="Set the store with delete protection"
            />
          </div>
          <KodexaResourceDelete :resource="dataStore" label="Data Store" @deleted="emit('step-deleted')" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
