<script setup lang="ts">
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { filterIcon } from "@progress/kendo-svg-icons";
import type { ProductSubscription } from "~/model";
import appStore from "~/store";
import {
  deleteProductSubscription,
  useListProductSubscriptions,
} from "~/api/product-subscriptions/product-subscriptions";

const { currentOrganization } = storeToRefs(appStore.organizationStore);

// Setup our grid helpers
const gridHelper = createGridHelper(`subscriptions-${currentOrganization.value?.id}`, {
  pageSize: 10,
  filter: "",
  sort: "",
  query: "",
  page: 1,
}, `organization.id: '${currentOrganization.value.id}'`);
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListProductSubscriptions(gridQuery.value);

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements as number);
    gridHelper.saveState();
  }
});

const columns = [
  {
    title: "Product",
    field: "product.name",
  },
  {
    title: " ",
    field: "id",
    width: "250px",
    cell: "actions",
  },
];
const showAddSubscription = ref(false);

function openNew() {
  showAddSubscription.value = true;
}

async function deleteIt(productSubscription: ProductSubscription) {
  await deleteProductSubscription(productSubscription.id);
}
</script>

<template>
  <div style="height: calc(100vh - 69px);">
    <div class="mx-4">
      <Toolbar class="border-0 bg-white">
        <Button :svg-icon="filterIcon" title="Filter" :togglable="true" @click="gridHelper.toggleFilter()" />
        <KodexaArticle
          class="mt-1" article-id="9034626" text="Learn more about organization subscriptions"
          :slide="true"
        />
        <ToolbarSpacer />
        <KodexaButton id="addSubscription" icon="plus" @click="openNew">
          Add Subscription
        </KodexaButton>
      </Toolbar>
      <Grid
        style="height: calc(100vh - 15rem);"
        :data-items="data ? data.content : []"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip as number"
        :total="pageSettings.total as number"
        :page-size="pageSettings.take as number"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :error="error" :is-loading="isLoading" :is-error="isError"
            no-records-message="No subscriptions found"
          />
        </GridNoRecords>
        <template #actions="{ props }">
          <td>
            <KodexaButton
              id="deleteSubscription"
              size="small"
              icon="delete"
              type="danger"
              @click="deleteIt(props.dataItem)"
            >
              Delete
            </KodexaButton>
          </td>
        </template>
      </Grid>
      <AddSubscription v-model="showAddSubscription" @refetch-members="refetch" />
    </div>
  </div>
</template>
