<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import type { DataAttribute, DataObject } from "~/model";
import appStore from "~/store";
import type { TagMetadata } from "~/store/useProject";
import type { AttributeEditorOptions } from "~/components/dataObject/attribute-editor-options";
import { getAttributeValueByTaxonType } from "~/components/util/attribute-utils";
import { TreeView, processTreeViewItems } from "@progress/kendo-vue-treeview";
import { Toolbar } from "@progress/kendo-vue-buttons";
import KodexaDataAttributeEditor from "~/components/dataObject/kodexa-data-attribute-editor.vue";
import KodexaReadOnlyAttribute from "~/components/dataObject/kodexa-read-only-attribute.vue";
import { createDocumentViewerStore } from "~/store/useDocumentView";

const props = defineProps({
  groupTaxonMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObjects: {
    type: Array as PropType<DataObject[]>,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: null,
  },
  tabIndex: {
    type: Number,
    required: false,
  },
  currentPage: {
    type: Number,
    default: 0,
  },
  isLoading: Boolean,
  isError: Boolean,
  error: Object,
  viewId: String,
  showHeader: {
    type: Boolean,
    default: true,
  },
});

const { sidecarPanelOpen } = storeToRefs(appStore.workspaceStore);
const { tagMetadataMap } = storeToRefs(appStore.projectStore);
const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);
const useSidebar = createSidecar(currentWorkspaceId.value);
const { currentPage, pageTags } = storeToRefs(useSidebar);
const { activeSelectionView } = storeToRefs(appStore.workspaceStore);
const editorOptions = ref<AttributeEditorOptions>({ showPreview: false });
const expand = ref({ ids: [] as string[], idField: "id" });
const originalExpandState = ref<string[]>([]);
const searchQuery = ref('');
const showLabelsWithNoValues = ref(false);
const limitToPage = ref(false);
const matchPageTags = ref(false);

const ensureRootInitiallyExpanded = () => {
  const rootId = props.groupTaxonMetadata?.taxon?.id;
  if (rootId && !originalExpandState.value.includes(rootId)) {
    originalExpandState.value.push(rootId);
    expand.value.ids.push(rootId);
  }
};

onMounted(ensureRootInitiallyExpanded);

watch(() => props.groupTaxonMetadata, ensureRootInitiallyExpanded, { deep: true });

const buildTreeItem = (dataObj: DataObject, taxonMap: Map<string, any>, depth = 0, parent: any = null, processedIds: Set<string> = new Set()) => {
  if (depth > 10 || processedIds.has(dataObj.id)) return null;
  processedIds.add(dataObj.id);

  const taxon = taxonMap.get(dataObj.path) || {};
  if (taxon.notUserLabelled) return null;

  const treeItem = {
    id: dataObj.id,
    label: dataObj.label || taxon.label || 'Unnamed',
    path: dataObj.path,
    taxon,
    dataObject: dataObj,
    value: null,
    children: [],
    hasChildren: false,
    parent,
    hasAttributeType: false,
    pageNumber: null,
  };

  const childTaxonMap = new Map(
    taxon.children?.filter(childTaxon => !childTaxon.notUserLabelled)
      .map((childTaxon, index) => [childTaxon.path, index]) || []
  );

  const attributeItems = dataObj.attributes?.flatMap(attr => {
    const attrTaxon = taxonMap.get(attr.path);
    if (attrTaxon && !attrTaxon.notUserLabelled) {
      return [{
        id: attr.id,
        label: attrTaxon.label || attr.path,
        path: attr.path,
        taxon: attrTaxon,
        dataObject: dataObj,
        value: getAttributeValueByTaxonType(attr, tagMetadataMap.value),
        children: [],
        hasChildren: false,
        parent: treeItem,
        hasAttributeType: true,
        hasValue: attr.value != null && attr.value !== '',
        sortIndex: childTaxonMap.get(attr.path) ?? Infinity,
        pageNumber: attr.dataFeatures?.page_number,
      }];
    }
    return [];
  }) || [];

  const childDataObjects = props.dataObjects.filter(obj => obj.parentId === dataObj.id && !processedIds.has(obj.id));
  const childItems = childDataObjects
    .map(childObj => {
      const childItem = buildTreeItem(childObj, taxonMap, depth + 1, treeItem, processedIds);
      if (childItem) {
        childItem.sortIndex = childTaxonMap.get(childObj.path) ?? Infinity;
      }
      return childItem;
    })
    .filter(Boolean);

  // Add children from taxon structure even if there are no corresponding data objects
  if (taxon.children) {
    taxon.children.forEach((childTaxon, index) => {
      if (!childTaxon.notUserLabelled && ![...attributeItems, ...childItems].some(child => child.path === childTaxon.path)) {
        const childItem = {
          id: childTaxon.id || childTaxon.path,
          label: childTaxon.label || 'Unnamed',
          path: childTaxon.path,
          taxon: childTaxon,
          dataObject: null,
          value: null,
          children: [],
          hasChildren: false,
          parent: treeItem,
          hasAttributeType: false,
          sortIndex: index,
        };
        
        // Recursively add grandchildren
        if (childTaxon.children) {
          childItem.children = childTaxon.children
            .filter(grandchildTaxon => !grandchildTaxon.notUserLabelled)
            .map((grandchildTaxon, grandchildIndex) => ({
              id: grandchildTaxon.id || grandchildTaxon.path,
              label: grandchildTaxon.label || 'Unnamed',
              path: grandchildTaxon.path,
              taxon: grandchildTaxon,
              dataObject: null,
              value: null,
              children: [],
              hasChildren: false,
              parent: childItem,
              hasAttributeType: false,
              sortIndex: grandchildIndex,
            }));
          childItem.hasChildren = childItem.children.length > 0;
        }
        
        childItems.push(childItem);
      }
    });
  }

  treeItem.children = [...attributeItems, ...childItems].sort((a, b) => a.sortIndex - b.sortIndex);
  treeItem.hasChildren = treeItem.children.length > 0;

  return treeItem;
};

const sortTreeItems = (items: any[]) => {
  return items.sort((a, b) => a.label.localeCompare(b.label));
};

const flattenTaxon = (taxon: any): [string, any][] => {
  if (!taxon) return [];
  const result: [string, any][] = [[taxon.path, taxon]];
  if (taxon.children && Array.isArray(taxon.children)) {
    taxon.children.forEach(child => {
      result.push(...flattenTaxon(child));
    });
  }
  return result;
};

const findUnmatchedTaxons = (taxonMap: Map<string, any>, dataObjects: DataObject[]): any[] => {
  const unmatchedTaxons: any[] = [];

  taxonMap.forEach((taxon, path) => {
    const matchingDataObject = dataObjects.find(obj => obj.path === path);
    const matchingAttribute = dataObjects.flatMap(obj => obj.attributes).find(attr => attr.path === path);

    if (!matchingDataObject && !matchingAttribute) {
      unmatchedTaxons.push(taxon);
    }
  });

  return unmatchedTaxons;
};

const treeDataItems = computed(() => {
  const taxonMap = new Map(flattenTaxon(props.groupTaxonMetadata?.taxon));
  const rootDataObjects = props.dataObjects.filter(obj => !obj.parentId);
  const processedIds = new Set<string>();

  if (props.groupTaxonMetadata && props.groupTaxonMetadata.taxon) {
    if (Array.isArray(props.groupTaxonMetadata.taxon)) {
      props.groupTaxonMetadata.taxon.forEach(t => {
        if (!t.notUserLabelled) {
          taxonMap.set(t.path, t);
          flattenTaxon(t).forEach(([path, taxon]) => {
            if (!taxon.notUserLabelled) {
              taxonMap.set(path, taxon);
            }
          });
        }
      });
    } else {
      if (!props.groupTaxonMetadata.taxon.notUserLabelled) {
        taxonMap.set(props.groupTaxonMetadata.taxon.path, props.groupTaxonMetadata.taxon);
        flattenTaxon(props.groupTaxonMetadata.taxon).forEach(([path, taxon]) => {
          if (!taxon.notUserLabelled) {
            taxonMap.set(path, taxon);
          }
        });
      }
    }
  }

  let items = rootDataObjects.map(obj => buildTreeItem(obj, taxonMap, 0, null, processedIds))
    .filter(item => item !== null);

  const rootTaxons = Array.isArray(props.groupTaxonMetadata.taxon)
    ? props.groupTaxonMetadata.taxon
    : [props.groupTaxonMetadata.taxon];

  rootTaxons.forEach(rootTaxon => {
    const existingItem = items.find(item => item.path === rootTaxon.path);
    if (!existingItem) {
      items.push({
        id: rootTaxon.id || rootTaxon.path,
        label: rootTaxon.label || 'Unnamed',
        path: rootTaxon.path,
        taxon: rootTaxon,
        dataObject: null,
        value: null,
        children: [],
        hasChildren: false,
        parent: null,
        hasAttributeType: false,
      });
    }
  });

  return processTreeViewItems(sortTreeItems(items), { expand: expand.value, childrenField: 'children' });
});

const isSelectionAttribute = (item: any): boolean => {
  if (item.dataObject?.attributes) {
    const matchingAttr = item.dataObject.attributes.find(attr => attr.path === item.path);
    return matchingAttr?.typeAtCreation === 'SELECTION';
  }
  return false;
};

const onExpandChange = (event: any) => {
  const index = expand.value.ids.indexOf(event.item.id);
  if (index === -1) {
    expand.value.ids.push(event.item.id);
    if (!searchQuery.value) {
      originalExpandState.value.push(event.item.id);
    }
  } else {
    expand.value.ids.splice(index, 1);
    if (!searchQuery.value) {
      const originalIndex = originalExpandState.value.indexOf(event.item.id);
      if (originalIndex !== -1) {
        originalExpandState.value.splice(originalIndex, 1);
      }
    }
  }
};

const toggleSidecar = () => appStore.workspaceStore.toggleSidecar();

const showInContext = (item: any) => {
  if (item.dataObject && item.taxon) {
    const useSidebar = createSidecar(currentWorkspaceId.value);
    const matchingAttribute = item.dataObject.attributes.find(
      (attr: DataAttribute) => attr.path === item.taxon.path
    );
    useSidebar.focusAttribute(matchingAttribute || item.taxon, item.dataObject);
  }
};

const getIconName = (item: any): string => {
  if (item.taxon.group || item.taxon.taxonType === 'DATA_OBJECT') return 'folder-table-outline';
  if (item.taxon.taxonType === 'SECTION') return 'minus';
  if (item.taxon.valuePath === 'FORMULA') return 'math-integral-box';
  if (item.taxon.valuePath === 'METADATA') return 'file-document-outline';
  return 'tag';
};

const getAttributeComponent = (item: any) => {
  return isSelectionAttribute(item) ? KodexaDataAttributeEditor : KodexaReadOnlyAttribute;
};

const shouldShowAttribute = (item: any): boolean => {
  return item.dataObject && tagMetadataMap.value.has(item.path) && !item.hasChildren;
};

const filterTreeItems = (items: any[]): any[] => {
  return items.reduce((acc, item) => {
    const shouldInclude = (showLabelsWithNoValues.value && !item.hasValue) ||
      (!showLabelsWithNoValues.value && item.hasValue);

    if (shouldInclude || item.children) {
      const filteredChildren = item.children ? filterTreeItems(item.children) : [];
      if (filteredChildren.length > 0 || shouldInclude) {
        acc.push({
          ...item,
          children: filteredChildren
        });
      }
    }
    return acc;
  }, []);
};

const filteredTreeDataItems = computed(() => {
  let items = treeDataItems.value;

  if (limitToPage.value) {
    items = filterTreeByPage(items, currentPage.value + 1);
  }

  if (!searchQuery.value) {
    expand.value.ids = [...originalExpandState.value];
    return filterTreeItems(items);
  }

  if (originalExpandState.value.length === 0) {
    originalExpandState.value = [...expand.value.ids];
  }

  const filterTree = (items, path = []) => {
    return items.reduce((acc, item) => {
      const matchesSearch = 
        item.label.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        (item.value && item.value.toString().toLowerCase().includes(searchQuery.value.toLowerCase()));
      let keepItem = matchesSearch;
      const currentPath = [...path, item.id];

      if (item.children && item.children.length) {
        const filteredChildren = filterTree(item.children, currentPath);
        if (filteredChildren.length > 0) {
          item = { ...item, children: filteredChildren };
          keepItem = true;
        }
      }

      if (keepItem) {
        currentPath.forEach(id => {
          if (!expand.value.ids.includes(id)) {
            expand.value.ids.push(id);
          }
        });
        acc.push(item);
      }

      return acc;
    }, []);
  };

  const filteredItems = filterTree(treeDataItems.value);
  ensureRootInitiallyExpanded();
  return filterTreeItems(filteredItems);
});

const pageTagFilteredTreeDataItems = computed(() => {
  if (!matchPageTags.value) {
    return filteredTreeDataItems.value;
  }

  const pageTagLabels = pageTags.value.map(tag => tag.metadata.label);

  const filterByPageTags = (items) => {
    return items.filter(item => {
      if (pageTagLabels.includes(item.label)) {
        return true;
      }
      if (item.children && item.children.length) {
        item.children = filterByPageTags(item.children);
        return item.children.length > 0;
      }
      return false;
    });
  };

  return filterByPageTags(filteredTreeDataItems.value);
});

const filterTreeByPage = (items: any[], pageNumber: number): any[] => {
  return items.reduce((acc, item) => {
    if (item.pageNumber === pageNumber || item.children) {
      const filteredChildren = item.children ? filterTreeByPage(item.children, pageNumber) : [];
      if (item.pageNumber === pageNumber || filteredChildren.length > 0) {
        acc.push({
          ...item,
          children: filteredChildren
        });
      }
    }
    return acc;
  }, []);
};

watch(limitToPage, () => {
  // This will trigger a re-computation of filteredTreeDataItems
});

watch(matchPageTags, (newValue) => {
  if (!newValue) {
    // Reset to original state when unchecked
    expand.value.ids = [...originalExpandState.value];
  }
});

watch(searchQuery, (newValue, oldValue) => {
  if (newValue === '' && oldValue !== '') {
    expand.value.ids = [...originalExpandState.value];
  }
});

const hasActiveSelection = computed(() => {
  if (activeSelectionView.value?.viewId) {
    const view = appStore.workspaceStore.getViewById(activeSelectionView.value.viewId);
    if (view?.viewType === "document") {
      const documentView = createDocumentViewerStore(view.id);
      return documentView?.selectionContext?.selectedNodes.length > 0;
    }
  }
  return false;
});

const addSelectedValue = (item: any) => {
  if (!activeSelectionView.value?.viewId) return;

  const view = appStore.workspaceStore.getViewById(activeSelectionView.value.viewId);
  if (view?.viewType !== "document") return;

  const documentView = createDocumentViewerStore(view.id);
  if (!documentView?.selectionContext?.selectedNodes.length) return;

  let currentItem = item;
  while (currentItem && !currentItem.dataObject) {
    currentItem = currentItem.parent;
  }

  if (!currentItem?.dataObject) return;

  if (currentItem.dataObject.documentFamily.id !== documentView.documentFamily.id) return;

  appStore.workspaceStore.loadDataObject(currentItem.dataObject);

  const tagMetadata = tagMetadataMap.value.get(item.path);
  if (tagMetadata) {
    documentView.addTag(tagMetadata, documentView.selectionContext.selectedNodes, { groupUuid: currentItem.dataObject.groupUuid });
  }
};

const handleAddSelectedValue = (item: any) => {
  addSelectedValue(item);
  return false;
};

const addSiblingItem = async (item: any) => {
  if (item.dataObject) {
    await appStore.workspaceStore.addSiblingDataObjectByUuid(item.dataObject.uuid);
  }
};

const deleteItem = (item: any) => {
  if (item.dataObject?.uuid) {
    appStore.workspaceStore.deleteDataObjectByUuid(item.dataObject.uuid);
  }
};
</script>

<template>
  <div>
    <div class="mt-2">
      <Toolbar :class="{ 'bg-white border-0': showHeader, 'bg-gray-100': !showHeader }">
        <KodexaTextInput
          v-model="searchQuery"
          :show-clear="true"
          type="text"
          name="filterTree"
          class="mr-2 -ml-1 block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm"
          placeholder="Search"
        />
        <KodexaButton
          :icon="sidecarPanelOpen ? 'close' : 'dock-right'"
          size="small"
          :title="sidecarPanelOpen ? 'Close Sidecar' : 'Open Sidecar'"
          type="secondary"
          @click="toggleSidecar"
        >
          {{ sidecarPanelOpen ? 'Close' : 'Open' }} Sidecar
        </KodexaButton>
      </Toolbar>
      <div class="ml-2 sm:flex sm:flex-wrap sm:items-center">
        <div class="mr-4 mb-2 sm:mb-0">
          <KodexaCheckbox
            v-model="showLabelsWithNoValues"
            label="Show labels with no value"
            name="noValue"
          />
        </div>
        <div class="mr-4 mb-2 sm:mb-0">
          <KodexaCheckbox
            v-model="limitToPage"
            label="Limit to page"
            name="limitToPage"
          />
        </div>
        <div class="mr-4 mb-2 sm:mb-0">
          <KodexaCheckbox
            v-model="matchPageTags"
            label="Match the page tags"
            name="matchPageTags"
          />
        </div>
      </div>
      <hr class="my-5">
      <TreeView
      :data-items="pageTagFilteredTreeDataItems"
      text-field="label"
      children-field="children"
      :expand-icons="true"
      item="item"
      :expand="expand"
      @expandchange="onExpandChange"
    >
      <template #item="{ props }">
        <div @click="showInContext(props.item)">
            <span>
              <MaterialDesignIcon
                :name="getIconName(props.item)"
                size="14"
                class="mr-1"
                :style="{ color: props.item.taxon.color }"
              />
              <span>{{ props.item.label || "Missing Name" }}</span>
            </span>
            <div v-if="!props.item.hasChildren && hasActiveSelection" class="mt-2 ml-4 text-sm text-blue-500 selected-value cursor-pointer" @click="() => handleAddSelectedValue(props.item)">
              Add Selected Value
            </div>
            <component
              :is="getAttributeComponent(props.item)"
              v-if="shouldShowAttribute(props.item) && !showLabelsWithNoValues"
              :view-id="viewId"
              :tag-metadata="tagMetadataMap.get(props.item.path)"
              :data-object="props.item.dataObject"
              :editor-options="editorOptions"
            />
            <span v-if="props.item.taxon && (props.item.taxon.group || props.item.taxon.taxonType === 'DATA_OBJECT')" class="ml-2">
              <MaterialDesignIcon
                name="plus"
                size="14"
                class="cursor-pointer"
                @click.stop="addSiblingItem(props.item)"
              />
              <MaterialDesignIcon
                name="trash-can-outline"
                size="14"
                class="cursor-pointer ml-1"
                @click.stop="deleteItem(props.item)"
              />
            </span>
          </div>
      </template>
    </TreeView>
    </div>
  </div>
</template>

<style scoped>
:deep(.k-treeview-leaf) {
  width: 300px !important;
  display: block !important;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
}

:deep(.k-treeview-leaf:focus),
:deep(.k-treeview-leaf.k-focus) {
  box-shadow: none;
}

:deep(.k-animation-container) {
  z-index: auto !important;
}

.selected-value {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.selected-value:hover {
  color: #0056b3;
  text-decoration: underline;
}
</style>
